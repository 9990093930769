<template>
  <div>
    <v-alert
      v-if="!roleCheck('userAdmin')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="alert-style"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="inviteMembersList"
        :search="search"
        disable-sort
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>
              <span v-if="item.email_id !== null">
                {{ item.email_id }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.organization_name !== null">
                {{ item.organization_name }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td class="drop-down-hover">
              <v-chip
                v-if="item.member_role_id !== null"
                outlined
                small
                class="ml-2"
                color="#37474F"
                light
              >
                {{ item.member_role_id.member_role }}
              </v-chip>
            </td>
            <td class="drop-down-hover">
              <span>{{ item.access_level | convertAccessLevel }}</span>
            </td>
            <td>
              {{ item.created_at | convertToLocal }}
            </td>
            <td>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
                :offset-x="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    fab
                    small
                    color="transparent"
                    elevation="0"
                    :disabled="isAccountUser(item.invited_by.external_id)"
                    v-on="on"
                  >
                    <v-icon color="black">
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>

                <v-list class="listing-buttons">
                  <v-list-item
                    link
                    @click="deleteMember(item.id)"
                  >
                    <v-icon color="#C62828">
                      mdi-trash-can-outline
                    </v-icon>
                    Delete
                  </v-list-item>
                  <v-list-item
                    link
                    @click="updateInvitationMember(item)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                    Edit
                  </v-list-item>
                  <v-list-item
                    v-if="isAccountUser(item.invited_by.external_id)"
                    link
                    @click="confirmAppUser(item, 'approved')"
                  >
                    <v-icon>mdi-account-check-outline</v-icon>
                    Accept
                  </v-list-item>
                  <v-list-item
                    v-if="isAccountUser(item.invited_by.external_id)"
                    link
                    @click="confirmAppUser(item, 'declined')"
                  >
                    <v-icon>mdi-account-remove-outline</v-icon>
                    Decline
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <delete-app-user
      v-if="showDelete"
      :title="'Delete this app user ?'"
      :message="'Action will permanently remove the  user from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <edit-invitation-member
      v-if="showForm"
      :set-update-details="setUpdateDetails"
      @closed="showForm = false"
      @success="getInviteMembersList()"
    />
  </div>
</template>

  <script>
    import moment from 'moment';
    import spinner from 'src/views/dashboard/component/SpinnerCentre';
    import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
    import EditInvitationMember from './EditInvitationMember.vue';
    import global from 'src/mixins/global';
    import Constants from 'src/constants';

    export default {
      name: 'InviteMembersList',
      components: {
        'centre-spinner': spinner,
        'delete-app-user': DeleteDialog,
        'edit-invitation-member': EditInvitationMember,
      },
      filters: {
        convertToLocal (stringDatetime) {
          return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
        },
        convertAccessLevel (val) {
        if (val === 'account_admin') {
          return 'Account Administrator';
        } else if (val === 'project_admin') {
          return 'Project Administrator';
        } else {
          return 'Project Member';
        }
      },
      },
      mixins: [global],
      data () {
        return {
          search: '',
          loading: false,
          showDelete: false,
          userId: '',
          setUpdateDetails: null,
          showForm: false,
          selectedUserRole: '',
          selectedAccessLevel: '',
          headers: [
            { text: 'Name', align: 'start', value: 'first_name' },
            { text: 'Email', value: 'email' },
            { text: 'Organization', value: 'organization.name' },
            { text: 'Member Role', value: 'member_role.name' },
            { text: 'Access Level', value: 'access_level' },
            { text: 'Added On', value: 'created_at' },
            { text: 'More', value: 'more' },
          ],
            accessLevelList: [
              {
                name: 'Account Adminstrator',
                id: 'account_admin',
              },
              {
                name: 'Project Adminstrator',
                id: 'project_admin',
              },
              {
                name: 'Project Member',
                id: 'project_member',
              },
            ],
        };
      },
      computed: {
        inviteMembersList () {
          return this.$store.getters['members/getInviteMembersList'];
        },
        membersRolesList () {
          return this.$store.getters['members/getMembersRolesList'];
        },
        userProfile () {
          return this.$store.getters['userprofile/getUserProfile'];
        },
        usersRoles () {
          return this.$store.getters['users/getUsersRoles'];
        },
      },
      async mounted () {
        await this.accessChecker();
      },
      methods: {
        async accessChecker () {
          if (this.roleCheck('userAdmin')) {
            await this.getInviteMembersList();
            await this.getUsersRoles();
          }
        },
        async getInviteMembersList () {
          this.loading = true;
          await this.$store.dispatch('members/fetchInviteMembersList', {
            params: {
              params: {
                name: null,
                requestStatus: 'invited',
              },
            },
          }).catch(() => {
            this.loading = false;
          });
          this.loading = false;
        },
        async getUsersRoles () {
          await this.$store.dispatch('users/fetchUsersRoles');
        },
        isAccountUser (invitedBy) {
          if (this.accessCheck('account_admin')) {
            if (invitedBy === this.userProfile.organization.external_id) {
              return false;
            }
            return true;
          } else {
            return true;
          }
        },
        updateInvitationMember (data) {
          this.setUpdateDetails = data;
          this.showForm = true;
        },
        async updateUserByAccessLevel (id) {
            this.loading = true;
            await this.$store.dispatch('members/updateInviteMembersList', {
              updateDetails: {
                member_role: this.selectedUserRole,
                access_level: id,
              },
              userId: this.userId,
            }).then(response => {
              this.$store.dispatch('alert/onAlert', {
                message: 'User updated successfully.',
                type: Constants.ALERT_TYPE_SUCCESS,
              });
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            });
          },
        deleteMember (id) {
          this.userId = id;
          this.showDelete = true;
        },
        deleteConfirmed () {
            this.loading = true;
            this.$store.dispatch('users/deleteUser', {
              userId: this.userId,
            }).then(response => {
              this.$store.dispatch('alert/onAlert', {
                message: 'Cancelled user Request.',
                type: Constants.ALERT_TYPE_SUCCESS,
              });
                this.showDelete = false;
                this.loading = false;
              }).catch(() => {
                this.loading = false;
              });
            },
      },
    };
  </script>
  <style scoped>
  .title-style {
    color: #37474F;
  }
  .icon-style {
    color: #37474F;
    font-size: 45px;
  }
  .v-data-table::v-deep th {
    font-size: 12px !important;
    color: #37474F !important;
    font-weight: bold !important;
  }
  .v-data-table::v-deep td {
    font-size: 13px !important;
    color: #37474F !important;
  }
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: #CFD8DC;
  }
  .v-data-table {
    background-color: #ECEFF1
  }
  .alert-style {
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
  }
  .v-list {
    padding: 0px 0px;
  }
  .listing-buttons .v-icon.v-icon {
    padding-right: 10px;
  }
  .drop-down-hover .button-visiblity {
    opacity: 0;
    visibility: hidden;
  }
  .drop-down-hover:hover .button-visiblity {
    opacity: 1;
    visibility: visible;
  }
  .member-v-list{
    max-height: 300px;
    overflow-y: auto
  }
  .v-card {
    margin-top: 0px!important;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
  }
  </style>
