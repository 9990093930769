<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getMembersList()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-group
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Account Members
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getRoles()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Roles
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-apps
                </v-icon>
              </v-list-item-icon>
              Widgets
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getMembersList()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-group
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Account Members
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getRoles()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Roles
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <members-list v-if="showMembersList" />
      <roles-list v-if="showRolesList" />
    </div>
  </v-container>
</template>

  <script>
   import MembersList from './MembersList.vue';
   import RolesList from './RolesList.vue';
   export default {
      name: 'Widgets',
      components: {
        'members-list': MembersList,
        'roles-list': RolesList,
      },
      data: () => ({
        selectedItem: -1,
        showMembersList: true,
        showRolesList: false,
      }),

      computed: {
        isMobile () {
          if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
            return false;
          } else {
            return true;
          }
        },
      },
      mounted () {
        this.selectedItem = 0;
      },
      methods: {
        getMembersList () {
          this.showMembersList = true;
          this.showRolesList = false;
        },
        getRoles () {
          this.showMembersList = false;
          this.showRolesList = true;
        },
      },
    };

  </script>
  <style scoped>
  .nav-title-style {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: #37474F;
    font-size: 25px;
  }
  .alert-style {
    color: #C62828;
    font-size: 25px;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
  }
  </style>
