<template>
  <div>
    <br>
    <h2 class="title-style pl-6 pt-3">
      <v-icon class="icon-style">
        mdi-account
      </v-icon>Roles
    </h2>
    <br>
    <v-col class="text-left pl-10 pt-3">
      <v-btn
        v-if="accessCheck('account_admin')"
        color="#FF3700"
        @click="showForm = true"
      >
        Add Role
      </v-btn>
    </v-col>
    <div>
      <v-row>
        <v-col cols="3">
          <div class="pl-10">
            <div class="listing-roles">
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
                persistent-hint
                :disabled="membersRolesList === 0"
                dense
              />
              <div
                v-if="loading"
                class="skeleton-loader"
              >
                <v-skeleton-loader type="heading, heading, heading, heading" />
              </div>
              <div v-else>
                <v-list
                  v-if="filteredItems.length !== 0"
                  class="member-v-list"
                >
                  <v-list-item
                    v-for="(item, i) in filteredItems"
                    :key="i"
                    class="listing-buttons background-color drop-down-hover"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.member_role }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-menu
                        bottom
                        origin="center center"
                        transition="scale-transition"
                        :offset-x="true"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="#3747">
                              mdi-dots-vertical
                            </v-icon>
                          </v-btn>
                        </template>

                        <v-list class="listing-buttons">
                          <v-list-item
                            link
                            @click="deleteMemberRole(item.id)"
                          >
                            <v-icon color="#C62828">
                              mdi-trash-can-outline
                            </v-icon>Remove Role
                          </v-list-item>
                          <v-list-item
                            link
                            @click="updateMemberRole(item.id, item.member_role)"
                          >
                            <v-icon>mdi-pencil-outline</v-icon>Rename Role
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
                <div
                  v-else
                  class="w-100 text-center"
                >
                  <p>No data found</p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="9">
          <div>
            <v-data-table
              :headers="headers"
              :items="accessList"
              disable-sort
              mobile-breakpoint="100"
              class="listing-access"
            >
              <template #item="{ item }">
                <tr>
                  <td>{{ item.account_admin }}</td>
                  <td>{{ item.project_admin }}</td>
                  <td>{{ item.project_member }}</td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <br>
        </v-col>
      </v-row>
    </div>
    <add-role
      v-if="showForm"
      @closed="showForm = false"
      @success="getMemberRoles()"
    />
    <delete-dialog
      v-if="showDeleteDialog"
      @closed="showDeleteDialog = false"
      @confirmed="confirmDelete()"
    />
    <edit-member-role
      v-if="showEditDialog"
      :role-id="roleId"
      :role-name="roleName"
      @closed="showEditDialog = false"
      @success="getMemberRoles()"
    />
  </div>
</template>

<script>
import AddRole from './AddRole.vue';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import EditMemberRole from './EditMemberRole.vue';
import Constants from 'src/constants';
import global from 'src/mixins/global';
export default {
  name: 'RolesList',
  components: {
    'add-role': AddRole,
    'delete-dialog': DeleteDialog,
    'edit-member-role': EditMemberRole,
  },
  mixins: [global],
  data () {
    return {
      showForm: false,
      showEditDialog: false,
      showDeleteDialog: false,
      search: '',
      roleId: null,
      roleName: '',
      loading: false,
      headers: [
        {
          text: 'Account Administrator',
          align: 'start',
          value: 'account_admin',
        },
        { text: 'Project Administrator', value: 'project_admin' },
        { text: 'Project Member', value: 'project_member' },
      ],
      accessList: [
        {
          account_admin:
            'Create or delete projects. And have all access to the projects',
          project_admin: 'Invite team members and manage access levels',
          project_member: 'Add or remove team members to project',
        },
        {
          account_admin: 'Add or remove account members',
          project_admin: 'Add or remove team members to project',
          project_member: 'Can view all projects details',
        },
        {
          account_admin: 'Change Account members access-levels and permissions',
          project_admin: 'Change the access levels and role of other project',
          project_member: 'Can create new company and App Users',
        },
        {
          account_admin: 'Change Account settings',
          project_admin: 'Admins and project members',
          project_member: 'Add new companies to projects',
        },
        {
          account_admin: 'Define which projects a project member can access',
          project_admin:
            'Review a project summary that includes member status and access rights metrics',
          project_member: 'Cannot create new projects or delete projects',
        },
        {
          account_admin: '',
          project_admin: 'Cannot create new projects or delete projects',
          project_member: 'View account members',
        },
        {
          account_admin: '',
          project_admin: "Cannot change account admin's permissions",
          project_member: 'View member permissions',
        },
        {
          account_admin: '',
          project_admin: '',
          project_member: 'View project details',
        },
      ],
    };
  },
  computed: {
    membersRolesList () {
      return this.$store.getters['members/getMembersRolesList'];
    },
    filteredItems () {
      if (this.membersRolesList.length !== 0) {
        const searchLower = this.search.toLowerCase();
        return this.membersRolesList.filter(item =>
          item.member_role.toLowerCase().includes(searchLower),
        );
      }
      return [];
    },
  },
  async mounted () {
    await this.getMemberRoles();
  },
  methods: {
    async getMemberRoles () {
      this.loading = true;
      await this.$store.dispatch('members/fetchMemberRoles');
      this.loading = false;
    },
    showUpdateField (id) {
      this.editableId = id;
    },
    updateMemberRole (id, name) {
      this.roleId = id;
      this.roleName = name;
      this.showEditDialog = true;
    },
    deleteMemberRole (id) {
      this.roleId = id;
      this.showDeleteDialog = true;
    },
    async confirmDelete () {
      await this.$store
        .dispatch('members/deleteMemberRole', {
          roleId: this.roleId,
        })
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Removed this role.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.getMemberRoles();
          this.showDeleteDialog = false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474f;
}

.icon-style {
  color: #37474f;
  font-size: 45px;
}

.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474f !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474f !important;
}

.v-data-table {
  background-color: #f8f8f8;
}

.my-tabs-background-style {
  background-color: #eceff1 !important;
}

.v-card__text {
  padding: 30px !important;
}

.background-color:hover {
  background: #dddddd;
  border-radius: 5px;
}

.drop-down-hover .button-visiblity {
  opacity: 0;
  visibility: hidden;
}

.drop-down-hover:hover .button-visiblity {
  opacity: 1;
  visibility: visible;
}

.member-v-list {
  margin-top: -10px;
  max-height: calc(100vh - 25rem);
  overflow-y: auto;
}

.v-list {
  padding: 0px 0px;
}

.listing-buttons .v-icon.v-icon {
  padding-right: 10px;
}

.listing-roles {
  background: #f8f8f8;
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.listing-access {
  background: #f8f8f8;
  border-radius: 10px;
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.theme--light.v-list {
  background: #f8f8f8;
}

.v-card {
  margin-top: 0px;
}

.v-list-item__icon {
  margin: 0px 0;
}

.skeleton-loader {
  padding: 0 10px;
}

.v-skeleton-loader >>> .v-skeleton-loader__heading {
  margin-bottom: 30px;
  height: 15px;
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(1) {
  width: 75%;
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(2) {
  width: 50%;
  background: rgba(0, 0, 0, 0.06);
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(3) {
  width: 25%;
  background: rgba(0, 0, 0, 0.03);
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(4) {
  width: 60%;
  background: rgba(0, 0, 0, 0.02);
}
</style>
