<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-account-edit
        </v-icon>
        Update Member Role
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <br>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="memberRole"
                class="field-style"
                :rules="[(v) => !!v || 'This field is required']"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="updateMemberRole()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

    <script>
    import Constants from 'src/constants';
    import spinner from 'src/views/dashboard/component/SpinnerCentre';

    export default {
      name: 'AddRole',
      components: {
        'centre-spinner': spinner,
      },
      props: {
        roleId: {
            type: String,
            required: true,
        },
        roleName: {
            type: String,
            required: true,
        },
      },
      data () {
        return {
          showModal: true,
          isFormValid: false,
          loading: false,
          memberRole: null,
        };
      },
      watch: {
        showModal: function (newval, oldval) {
          if (newval === false) {
            this.$emit('closed');
          }
        },
      },
      mounted () {
        this.loadOnMount();
      },
      methods: {
        async updateMemberRole () {
          if (this.$refs.form.validate() === false) {
            this.$store.dispatch('alert/onAlert', {
              message: 'Please fill the required fields before proceeding.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
          this.loading = true;
          await this.$store.dispatch('members/updateMemberRole', {
            data: {
              member_role: this.memberRole,
            },
            roleId: this.roleId,
          }).then(() => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Member role updated successfully successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.$emit('success');
            this.loading = false;
            this.showModal = false;
          }).catch(() => {
            this.loading = false;
          });
        },
        loadOnMount () {
            this.memberRole = this.roleName;
        },
      },
    };
    </script>

    <style scoped>
    .title-style {
      color: #37474F;
      margin-left: 30px;
    }
    .icon-style {
      color: #37474F;
      font-size: 45px;
    }
    .my-p-style {
      font-size: 18px;
      font-weight: bold;
      color: #FF3700;
      font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      text-align: center;
    }
    .my-p-style-sub {
      font-size: 16px;
      font-weight: bold;
      color: #546E7A;
      font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }
    </style>
