<template>
  <div>
    <br>
    <h2 class="title-style pl-6 pt-3">
      <v-icon class="icon-style">
        mdi-account-multiple
      </v-icon>Account Members
    </h2>
    <br>
    <v-col class="pl-10 pr-7">
      <v-alert
        v-if="checkUserSubStatus()"
        dense
        outlined
        prominent
        type="warning"
      >
        <v-row align="center">
          <v-col class="grow">
            <strong>You can only create 4 members:</strong>
            <font>To collaborate with more members and access more features, upgrade to a paid plan.</font>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col class="text-right">
      <v-btn
        v-if="checkUser()"
        color="#FF3700"
        :disabled="checkUserSubStatus()"
        @click="showForm = true"
      >
        Invite
      </v-btn>
    </v-col>
    <v-tabs
      v-model="tab"
      color="#FF3700"
      background-color="#ECEFF1"
      style="margin-left: 20px;"
    >
      <v-tab>Active</v-tab>
      <v-tab>Invite</v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item>
        <v-card-text>
          <active-members-list />
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <invite-members-list />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <create-member
      v-if="showForm"
      @closed="showForm = false"
      @success="getMemberDetails()"
    />
  </div>
</template>

<script>
import ActiveMembersList from './ActiveMembersList.vue';
import InviteMemberList from './InviteMemberList.vue';
import CreateMember from './CreateMember.vue';
import global from '../../../mixins/global';
export default {
  name: 'MemberList',
  components: {
    'active-members-list': ActiveMembersList,
    'invite-members-list': InviteMemberList,
    'create-member': CreateMember,
  },
  mixins: [global],
  data () {
    return {
      tab: null,
      showForm: false,
    };
  },
  computed: {
    inviteMembersList () {
      return this.$store.getters['members/getInviteMembersList'];
    },
  },
  methods: {
    checkUser () {
      if (this.roleCheck('userAdmin')) {
        if (this.accessCheck('account_admin')) {
          return true;
        }
        return false;
      }
      return false;
    },
    checkUserSubStatus () {
      if (this.subscriptionCheck('trial') && this.inviteMembersList.length === 3) {
        return true;
      }
      return false;
    },
    async getMemberDetails () {
      await this.$store.dispatch('members/fetchInviteMembersList', {
        params: {
          params: {
            name: null,
            requestStatus: 'invited',
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474f;
}

.icon-style {
  color: #37474f;
  font-size: 45px;
}

.my-tabs-background-style {
  background-color: #eceff1 !important;
}

.v-card__text {
  padding: 30px !important;
}

.v-alert {
    padding: 5px 15px;
}

.v-alert--outlined {
    background: #fb8a000e !important;
    border: thin solid currentColor !important;
}
</style>
